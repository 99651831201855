/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p",
    h5: "h5",
    h4: "h4",
    blockquote: "blockquote",
    strong: "strong",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Common Commands"), "\n", React.createElement(_components.h3, null, "Containers"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run node\n")), "\n", React.createElement(_components.p, null, "Runs and instances of an image. If the image does not exist docker will pull an instance"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker ps\n")), "\n", React.createElement(_components.p, null, "List running containers and basic information"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker ps -a\n")), "\n", React.createElement(_components.p, null, "List containers both running or stopped and basic information"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker stop silly_sammet\n")), "\n", React.createElement(_components.p, null, "Stops a running container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker rm silly_sammet\n")), "\n", React.createElement(_components.p, null, "Remove a stopped container run ", React.createElement(_components.code, null, "docker ps -a"), " to confirm removal"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run -p 80:80 docker/getting-started\n")), "\n", React.createElement(_components.p, null, "Runs docker in the foreground"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run -d -p 80:80 docker/getting-started\n")), "\n", React.createElement(_components.p, null, "the ", React.createElement(_components.code, null, "-d"), " flag will run docker in the background"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker attach <id>\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker attach <name>\n")), "\n", React.createElement(_components.h5, null, "Interactive Terminal"), "\n", React.createElement(_components.p, null, "By default docker containers do not accept inputs through the terminal to enable this use the ", React.createElement(_components.code, null, "-it"), " flag for interactive terminal"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker run -it\n")), "\n", React.createElement(_components.h5, null, "Port Mapping"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker\n")), "\n", React.createElement(_components.h4, null, "Tags"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker run node:12\n")), "\n", React.createElement(_components.p, null, "A tag is a flag to specify a particular flavor of an image, the command without a tag will default to ", React.createElement(_components.code, null, "latest")), "\n", React.createElement(_components.h3, null, "Images"), "\n", React.createElement(_components.h4, null, "Show Images"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker images\n")), "\n", React.createElement(_components.h4, null, "Remove an Image"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Note: the image cannot be in use by any container"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker rmi node\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker run node\n")), "\n", React.createElement(_components.p, null, "Runs and image if the image already exists on machine else pulls latest image and runs."), "\n", React.createElement(_components.h4, null, "Run Container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker run --rm -it -p 3000:3000/tcp <imageName>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker pull node\n")), "\n", React.createElement(_components.p, null, "Pulls an instance of an image"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Containers only run as log as the process they contain is running is live, such as a database or web service."), "\n"), "\n", React.createElement(_components.h4, null, "Build Image"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker build .\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker build ./path/to/dir\n")), "\n", React.createElement(_components.p, null, "or ", React.createElement(_components.strong, null, "best practice"), " tag your image with a name"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "docker build . -t <imageName>\n")), "\n", React.createElement(_components.h3, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://gist.github.com/adamveld12/4815792fadf119ef41bd"
  }, "Anatomy of a DockerFile")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=fqMOX6JJhGo"
  }, "Docker Tutorial for Beginners")), "\n", React.createElement(_components.h3, null, "For Review"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "port mapping"), "\n", React.createElement(_components.li, null, "volume mapping"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
